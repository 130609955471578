<template>
  <div class="top-bar" :style="bgcolor">
    <img :class="{'left-arrow': !iconIsClose, 'close': iconIsClose}" :src="iconUrl" @click="goBack" v-show="iconShow"/>
    <h2 class="title">{{title}}</h2>
  </div>
</template>

<script>
import leftArrow from '../../assets/img/left-arrow.png'
import close from '../../assets/img/close.png'

export default {
  name: 'TopBar',
  props: {
    title: {
      type: String,
      default: '小肾老师',
      required: false
    },
    icon: {
      type: String,
      default: 'left'
    },
    backgroundColor: {
      type: String,
      default: '#EDEDED'
    }
  },
  data() {
    return {
      iconUrl: leftArrow,
      iconIsClose: false,
      bgcolor: {
        backgroundColor: this.backgroundColor
      },
      iconShow: true
    }
  },
  mounted() {
    if(this.icon === 'close') {
      this.iconUrl = close
      this.iconIsClose = true
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    if(this.icon == 'none') {
      this.iconShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 11.7333vw;
  background-color: #EDEDED;
  z-index: 100;

  .left-arrow {
    position: absolute;
    left: 5.0667vw;
    top: 3.7333vw;
    width: 2.4vw;
    height: 4.2667vw;
  }

  .close {
    position: absolute;
    left: 5.0667vw;
    top: 3.7333vw;
    width: 3.2027vw;
    height: 3.6747vw;
  }

  .title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 4.2667vw;
    font-weight: 400;
    color: #212121;
    height: 6.4vw;
    line-height: 6.4vw;
  }
}
</style>